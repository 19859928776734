<template>
	<div>
		<b-row>
			<b-col lg="12" md="12" sm="12" class="heading-title">
				
						<h4>Create Parent Venue </h4>
					
						<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
					<b-breadcrumb-item active>Add</b-breadcrumb-item>
				</b-breadcrumb>
						
				
				
			</b-col>
			<!-- <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
		</b-row>
		<br />
		<b-card class="mb-4 w-100">
			<h6 class="custom-text"><span>Venue Details</span></h6>
			
			<b-form @submit.prevent="handleSubmit">
				<div class="d-flex flex-row ">
				<div class="p-2">
					<p style="font-weight: 600">
						Is Your Parent Venue Same As Vendor Company Name?
					</p>
				</div>
				<div class="p-2"><p class="font-weight-bold">Yes</p></div>
				<div class="p-2">
					<b-form-checkbox
					value="1"
					unchecked-value="0"
					v-model="same_as_yes"
					@input="sameAsYes1"
					>
					</b-form-checkbox>
				</div>
				<!-- <div class="p-2"><p class="font-weight-bold">No</p></div>
				<div class="p-2">
					<b-form-checkbox
					value="1"
					unchecked-value="0"
					v-model="same_as_no"
					@input="sameAsNo"
					>
					</b-form-checkbox>
				</div> -->
			</div>
				<b-row>
					<!-- part 1(1) -->
					<b-col md="6">
						<b-row class="align-items-center minus-mt-2">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="lname2">Company Name<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="c_name"
									type="text"
									placeholder="Enter your Company Name"
									v-model="$v.form.c_name.$model"
									:state="validateState('c_name')"
									readonly
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.c_name.$error"
									>
										<div v-if="!$v.form.c_name.required">
											Please enter your Company Name
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
							
						</b-row>
						
					</b-col>
					<b-col md="6">
						<b-row class="align-items-center minus-mt-2">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="nname2">Venue Name<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="v_name"
									type="text"
									placeholder="Enter your Venue Name"
									v-model="$v.form.v_name.$model"
									:state="validateState('v_name')"
									:readonly="same_as_yes==true"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.v_name.$error"
									>
										<div v-if="!$v.form.v_name.required">
											Please enter your Venue Name
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					
				</b-row>
				<hr class="super_hr">
				<b-row>
					<b-col md="6">
						
						<b-row class="py-3 align-items-center mt-3">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="uname2"> Pincode<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="pincode"
									type="text"
									placeholder="Enter your Pincode"
									v-model="$v.form.pincode.$model"
									:state="validateState('pincode')" 
                  @keyup="pincodeCheck()"
									></b-form-input>
									<b-form-invalid-feedback id="pehrs-1-live-feedback">
										<div v-if="!$v.form.pincode.required">
											Please enter your pincode
										</div>
										<div
										v-if="
										!$v.form.pincode.minLength ||
										!$v.form.pincode.numeric ||
										!$v.form.pincode.maxLength
										"
										>
											Please enter valid pincode
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
						
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center mt-3">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="lname2">City<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									name="city"
									type="text"
									placeholder="Enter your City"
									v-model="$v.form.city.$model"
									:state="validateState('city')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.city.$error"
									>
										<div v-if="!$v.form.city.required">
											Please enter your city
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					
					
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="uname2">State<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
								<b-form-input class="venue-custom-input"
								id="state"
								type="text"
								placeholder="Enter your State"
								v-model="$v.form.state.$model"
								:state="validateState('state')"
								></b-form-input>
								<b-form-invalid-feedback
								id="pehrs-1-live-feedback"
								v-if="$v.form.state.$error"
								>
									<div v-if="!$v.form.state.required">
										Please enter your state
									</div>
								</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="nname2">Country<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									name="country"
									type="text"
									placeholder="Enter your Country"
									v-model="$v.form.country.$model"
									:state="validateState('country')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.country.$error"
									>
										<div v-if="!$v.form.country.required">
											Please enter your country
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				
				<b-row>
					
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="uname2">Address<span class="text-danger">*</span> :</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-textarea class="venue-custom-input"
									id="address"
									type="text"
									placeholder="Enter your Address"
									v-model="$v.form.address.$model"
									:state="validateState('address')"
									></b-form-textarea>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.address.$error"
									>
										<div v-if="!$v.form.address.required">
											Please enter your address
										</div>
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="lname2">Show on map:</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-checkbox 
									id="checkbox-1"
									name="checkbox-1"
									value="1"
									unchecked-value="0"
									v-model="form.show_map"
									>
									</b-form-checkbox>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				
				
				<h6 class="custom-text"><span>Social media Details</span></h6>
				<b-row>
					
					<b-col md="6">
						
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left  mt-2">
								<label class="fw-medium mb-0" for="uname2">
									Facebook Url:</label
								>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="fb"
									type="text"
									placeholder="Enter your Facebook url"
									v-model="$v.form.facebook_url.$model"
									:state="validateState('facebook_url')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.facebook_url.$error"
									>
										Please enter valid facebook url
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="uname2">
									Instagram Url:</label
								>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="insta"
									type="text"
									placeholder="Enter your Instagram url"
									v-model="$v.form.instagram_url.$model"
									:state="validateState('instagram_url')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.instagram_url.$error"
									>
										Please enter valid instagram url
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="uname2"> Youtube Url:</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="yb"
									type="text"
									placeholder="Enter your Youtube url"
									v-model="$v.form.youtube_url.$model"
									:state="validateState('youtube_url')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.youtube_url.$error"
									>
										Please enter valid youtube url
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="uname2"> Twitter Url:</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="tw"
									type="text"
									placeholder="Enter your Twitter url"
									v-model="$v.form.twitter_url.$model"
									:state="validateState('twitter_url')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.twitter_url.$error"
									>
										Please enter valid twitter url
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="6">
						<b-row class="py-3 align-items-center">
							<b-col sm="4" class="text-left mt-2">
								<label class="fw-medium mb-0" for="uname2"> Website Url:</label>
							</b-col>
							<b-col sm="8">
								<b-form-group>
									<b-form-input class="venue-custom-input"
									id="tw"
									type="text"
									placeholder="Enter your website url"
									v-model="$v.form.website_url.$model"
									:state="validateState('website_url')"
									></b-form-input>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.website_url.$error"
									>
										Please enter valid website url
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<div class="text-center">
					<b-button to="/venues"  class="mt-2 btn-venuecolor">
						Back
					</b-button>
					&nbsp;
					<b-button 
					type="submit"
					class="mt-2 btn-venuecolor"
					:disabled="show_add_venue"
					>
					Add
					</b-button>
				</div>
			</b-form>
		</b-card>
		<b-modal
		id="parent_venue"
		:no-close-on-backdrop="true"
		size="sm"
		:hide-footer="true"
		:hide-header="true"
		:centered="true"
		>
			<h5 class="text-center my-4">Parent Venue created Successfully!</h5>
			<div style="text-align: center">
				<b-button variant="outline-primary" @click="createChild()"
				>Create Child Venue</b-button
				>
				<br />
				<br />
				<b-button variant="outline-secondary" @click="skipStep()"
				>Skip</b-button
				>
			</div>
		</b-modal>
		
	</div>
</template>
<script>
	import axios from "axios";
	import { validationMixin } from "vuelidate";
	import {
		required,
		minLength,
		maxLength,
		numeric,
		helpers,
		url,
	} from "vuelidate/lib/validators";
	/* const facebookCheck = helpers.regex(
	"facebookCheck",
	/^(https?:\/\/){0,1}(www\.){0,1}(facebook\.com\/)/
	);
	const instagramCheck = helpers.regex(
	"instagramCheck",
	/^(https?:\/\/){0,1}(www\.){0,1}(instagram\.com\/)/
	);
	const youtubeCheck = helpers.regex(
	"youtubeCheck",
	/^(https?:\/\/){0,1}(www\.){0,1}(youtube\.com\/)/
	);
	const twitterCheck = helpers.regex(
	"twitterCheck",
	/^(https?:\/\/){0,1}(www\.){0,1}(twitter\.com\/)/
	); */

	const websiteUrl = helpers.regex(
	"websiteUrl",
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z]+=\w+)*)?\/?$/gm
	);
	

	
	export default {
		name: "parent_venue_add",
		mixins: [validationMixin],
		data: () => ({
			title: "parent_venue_add",
			form: {
				c_name: "",
				v_name: "",
				address: "",
				state: "",
				city: "",
				country: "",
				show_map: "0",
				pincode: "",
				facebook_url: "",
				instagram_url: "",
				youtube_url: "",
				twitter_url: "",
				website_url: "",
			},
			same_as_yes: "0",
			same_as_no: "1",
			child_venue_id: "",
			show_add_venue: false,
		}),
		components: {},
		validations: {
			form: {
				c_name: {
					required,
				},
				v_name: {
					required,
				},
				address: {
					required,
				},
				state: {
					required,
				},
				city: {
					required,
				},
				country: {
					required,
				},
				pincode: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6),
					numeric,
				},
				facebook_url: {
					//facebookCheck,
					url,
				},
				instagram_url: {
				//	instagramCheck,
					url,
				},
				youtube_url: {
					//youtubeCheck,
					url,
				},
				twitter_url: {
				//	twitterCheck,
					url,
				},
				website_url: {
					websiteUrl,
				}, 
			},
		},
		methods: {
			validateState(c_name) {
				const { $dirty, $error } = this.$v.form[c_name];
				return $dirty ? !$error : null;
			},
			
			handleSubmit() {
				this.$v.form.$touch();
				if (!this.$v.form.$anyError) {
					this.show_add_venue = true;
					axios
					.post("/api/add_parent_venue", {
						venue_name: this.form.v_name,
						venue_company_name: this.form.c_name,
						venue_country: this.form.country,
						venue_state: this.form.state,
						venue_city: this.form.city,
						venue_address: this.form.address,
						venue_pincode: this.form.pincode,
						show_map: this.form.show_map,
						facebook_url: this.form.facebook_url,
						instagram_url: this.form.instagram_url,
						youtube_url: this.form.youtube_url,
						twitter_url: this.form.twitter_url,
						website_url: this.form.website_url,
					})
					.then((resp) => {
						this.show_add_venue = false;
						if (resp.data.status) {
							this.$v.form.$reset();
							this.child_venue_id = resp.data.venue_id;
							this.$bvModal.show("parent_venue");
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					});
				}
			},
			sameAsYes() {
				/* if (this.same_as_yes === "0") {
					this.same_as_no = "1";
				}
				
				if (this.same_as_yes === "1") {
					this.same_as_no = "0";
					
					axios.get("/api/get_company_details").then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.form.c_name = resp.data.data[0].company_name;
								this.form.city = resp.data.data[0].city;
								this.form.state = resp.data.data[0].state;
								this.form.country = resp.data.data[0].country;
								this.form.address = resp.data.data[0].address;
							}
						}
					});
				} */
				axios.get("/api/get_company_details").then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.form.c_name = resp.data.data[0].first_name+ ' ' + resp.data.data[0].last_name;
								/* this.form.city = resp.data.data[0].city;
								this.form.state = resp.data.data[0].state;
								this.form.country = resp.data.data[0].country;
								this.form.address = resp.data.data[0].address; */
							}
						}
					});
			},
			sameAsYes1() {
				if(this.same_as_yes==1)
				{
					this.form.v_name = this.form.c_name;
				}
				else
				{
					this.form.v_name = "";
				}
			},
			sameAsNo() {

				this.form.v_name = "";
				/* if (this.same_as_no === "0") {
					this.same_as_yes = "1";
				}
				
				if (this.same_as_no === "1") {
					this.same_as_yes = "0";
					//this.form.c_name = "";
					this.form.city = "";
					this.form.state = "";
					this.form.country = "";
					this.form.address = "";
				} */
			},
			skipStep() {
				this.$router.push("/venues");
			},
			createChild() {
				this.$router.push("/venues/Child_venue/index/" + this.child_venue_id);
			},
      pincodeCheck()
      {
        const str=this.form.pincode;
        axios
                .post("/api/pincodeCheck", {
                    pincode: str
				})
                .then((resp) => {
                    if (resp.data.pincode_city==0) {
                      this.form.city="";
                      this.form.state="";
                      this.form.country="";
                    }
                    else
                    {
	this.form.city=this.capitalized(resp.data.pincode_city.division);
                    this.form.state=this.capitalized(resp.data.pincode_city.state);
                    this.form.country=this.capitalized(resp.data.pincode_city.country);
                     
                    }
				});
      },
	capitalized(str) {
		const [firstLetter, ...rest] = str.split('');
      const upperCaseLetter = firstLetter.toUpperCase();
      
      if (firstLetter !== upperCaseLetter) {
        return firstLetter.toUpperCase() + rest.join('');
      }

      return firstLetter.toUpperCase() + rest.join('');
      
    },
	autourl()
	{
	this.form.website_url='https://www.';//(this.form.website_url > 0 ? "+" : "" ) + this.form.website_url + "%";
	}
		},
		mounted()
		{
			this.sameAsYes();
		}
	};
</script>
<style scoped>
	.form-group {
	margin: 0;
	}

</style>